export const FETCH_PRODUCER_FORMS_PACKAGES_REQUEST = 'FETCH_PRODUCER_FORMS_PACKAGES_REQUEST';
export const FETCH_PRODUCER_FORMS_PACKAGES_SUCCESS = 'FETCH_PRODUCER_FORMS_PACKAGES_SUCCESS';
export const FETCH_PRODUCER_FORMS_PACKAGES_FAILURE = 'FETCH_PRODUCER_FORMS_PACKAGES_FAILURE';
export const FETCH_PRODUCER_FORMS_PACKAGE_REQUEST = 'FETCH_PRODUCER_FORMS_PACKAGE_REQUEST';
export const FETCH_PRODUCER_FORMS_PACKAGE_SUCCESS = 'FETCH_PRODUCER_FORMS_PACKAGE_SUCCESS';
export const FETCH_PRODUCER_FORMS_PACKAGE_FAILURE = 'FETCH_PRODUCER_FORMS_PACKAGE_FAILURE';
export const REFRESH_PRODUCER_FORMS_PACKAGES = 'REFRESH_PRODUCER_FORMS_PACKAGES';
export const SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SEASON = 'SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SEASON';
export const SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_ID = 'SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_ID';
export const SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SALES_REPORT = 'SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SALES_REPORT';
export const SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_EXEMPTION = 'SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_EXEMPTION';
export const CLEAR_CURRENT_PRODUCER_FORMS_PACKAGE = 'CLEAR_CURRENT_PRODUCER_FORMS_PACKAGE';
export const POST_PRODUCER_STATE_PRODUCT_ITEM_SUCCESS = 'POST_PRODUCER_STATE_PRODUCT_ITEM_SUCCESS';
export const POST_PRODUCER_STATE_PRODUCT_ITEM_FAILURE = 'POST_PRODUCER_STATE_PRODUCT_ITEM_FAILURE';
export const CLEAR_PRODUCER_STATE_PRODUCT_ITEMS = 'CLEAR_PRODUCER_STATE_PRODUCT_ITEMS';
export const CREATE_DOMESTIC_SALES_REPORT_REQUEST = 'CREATE_DOMESTIC_SALES_REPORT_REQUEST';
export const CREATE_DOMESTIC_SALES_REPORT_SUCCESS = 'CREATE_DOMESTIC_SALES_REPORT_SUCCESS';
export const CREATE_DOMESTIC_SALES_REPORT_FAILURE = 'CREATE_DOMESTIC_SALES_REPORT_FAILURE';
export const PATCH_DOMESTIC_SALES_REPORT_REQUEST = 'PATCH_DOMESTIC_SALES_REPORT_REQUEST';
export const PATCH_DOMESTIC_SALES_REPORT_SUCCESS = 'PATCH_DOMESTIC_SALES_REPORT_SUCCESS';
export const PATCH_DOMESTIC_SALES_REPORT_FAILURE = 'PATCH_DOMESTIC_SALES_REPORT_FAILURE';
export const CREATE_EXEMPTION_REQUEST = 'CREATE_EXEMPTION_REQUEST';
export const CREATE_EXEMPTION_SUCCESS = 'CREATE_EXEMPTION_SUCCESS';
export const CREATE_EXEMPTION_FAILURE = 'CREATE_EXEMPTION_FAILURE';
export const PATCH_EXEMPTION_REQUEST = 'PATCH_EXEMPTION_REQUEST';
export const PATCH_EXEMPTION_SUCCESS = 'PATCH_EXEMPTION_SUCCESS';
export const PATCH_EXEMPTION_FAILURE = 'PATCH_EXEMPTION_FAILURE';