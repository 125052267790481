import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import loginReducer from './login/loginReducer';
import userReducer from './user/userReducer';
import companyReducer from './company/companyReducer';
import seasonReducer from './season/seasonReducer';
import formsPackageReducer from './formsPackage/formsPackageReducer';
import formReducer from './form/formReducer';
import invoiceReducer from './invoice/invoiceReducer';
import donationReducer from './donation/donationReducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  loginReducer,
  userReducer,
  companyReducer,
  seasonReducer,
  formsPackageReducer,
  formReducer,
  invoiceReducer,
  donationReducer
});
