import {
  CREATE_DONATION_REQUEST,
  CREATE_DONATION_SUCCESS,
  CREATE_DONATION_FAILURE,
  FETCH_DONATION_REQUEST,
  FETCH_DONATION_SUCCESS,
  FETCH_DONATION_FAILURE,
} from './donationTypes';
import history from '@iso/lib/helpers/history';
import { getAuthClient } from '../../library/drupal/DrupalAuth2';

const auth = getAuthClient();

export const createDonation = (data) => {
  const url = `/jsonapi/node/donation`;
  const options = {
    method: 'POST',
    headers: new Headers({
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }),
    mode: 'cors',
    body: JSON.stringify(data),
  };
  return async (dispatch) => {
    dispatch(createDonationRequest());
    try {
      const response = await auth.fetchWithAuthentication(url, options);
      if (response.ok) {
        dispatch(createDonationSuccess(JSON.stringify(response)));
        const data = await response.json();
        const newDonationID = data.data.id;
        history.push(
          `/dashboard/donation/${newDonationID}`
        );
      } else {
        dispatch(createDonationFailure('An error has occured.'));
      }
    } catch (error) {
      const errorMsg = error.message;
      dispatch(createDonationFailure(errorMsg));
    }
  };
};

export const fetchDonation = (donationUID) => {

  if (donationUID !== null) {

    //fetch a single donation
    const path = `/jsonapi/node/donation/${donationUID}`;
    const include = `include=field_season`;
    const url = `${path}?${include}`;
    const options = {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
      mode: 'cors',
    };
    return (dispatch) => {
      dispatch(fetchDonationRequest());
      auth
        .fetchWithAuthentication(url, options)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const donation = data;
          dispatch(fetchDonationSuccess(donation));
        })
        .catch((error) => {
          const errorMsg = error.message;
          dispatch(fetchDonationFailure(errorMsg));
        });
    };
  }
};

export const createDonationRequest = () => {
  return {
    type: CREATE_DONATION_REQUEST,
  };
};

export const createDonationSuccess = (success) => {
  return {
    type: CREATE_DONATION_SUCCESS,
    payload: success.data,
  };
};

export const createDonationFailure = (error) => {
  return {
    type: CREATE_DONATION_FAILURE,
    payload: error,
  };
};

export const fetchDonationRequest = () => {
  return {
    type: FETCH_DONATION_REQUEST,
  };
};

export const fetchDonationSuccess = (donation) => {
  return {
    type: FETCH_DONATION_SUCCESS,
    payload: donation.data,
  };
};

export const fetchDonationFailure = (error) => {
  return {
    type: FETCH_DONATION_FAILURE,
    payload: error,
  };
};