import {
FETCH_PRODUCER_FORMS_PACKAGES_REQUEST,
FETCH_PRODUCER_FORMS_PACKAGES_SUCCESS,
FETCH_PRODUCER_FORMS_PACKAGES_FAILURE,
FETCH_PRODUCER_FORMS_PACKAGE_REQUEST,
FETCH_PRODUCER_FORMS_PACKAGE_SUCCESS,
FETCH_PRODUCER_FORMS_PACKAGE_FAILURE,
REFRESH_PRODUCER_FORMS_PACKAGES,
SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SEASON,
SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_ID,
SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SALES_REPORT,
SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_EXEMPTION,
CLEAR_CURRENT_PRODUCER_FORMS_PACKAGE,
POST_PRODUCER_STATE_PRODUCT_ITEM_SUCCESS,
POST_PRODUCER_STATE_PRODUCT_ITEM_FAILURE,
CLEAR_PRODUCER_STATE_PRODUCT_ITEMS,
CREATE_DOMESTIC_SALES_REPORT_REQUEST,
CREATE_DOMESTIC_SALES_REPORT_SUCCESS,
CREATE_DOMESTIC_SALES_REPORT_FAILURE,
PATCH_DOMESTIC_SALES_REPORT_REQUEST,
PATCH_DOMESTIC_SALES_REPORT_SUCCESS,
PATCH_DOMESTIC_SALES_REPORT_FAILURE,
CREATE_EXEMPTION_REQUEST,
CREATE_EXEMPTION_SUCCESS,
CREATE_EXEMPTION_FAILURE,
PATCH_EXEMPTION_REQUEST,
PATCH_EXEMPTION_SUCCESS,
PATCH_EXEMPTION_FAILURE,
} from './formsPackageTypes';

const initialState = {
  loading: false,
  initialProducerFormsPackages: false,
  producerFormsPackages: [],
  currentProducerFormsPackage: null,
  saleReferences: [],
  error: '',
};

const formsPackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCER_FORMS_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCER_FORMS_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        initialProducerFormsPackages: true,
        producerFormsPackages: action.payload,
        error: '',
      };
    case FETCH_PRODUCER_FORMS_PACKAGES_FAILURE:
      return {
        ...state,
        loading: false,
        producerFormsPackages: [],
        error: action.payload,
      };
    case FETCH_PRODUCER_FORMS_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCER_FORMS_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProducerFormsPackage: action.payload,
        error: '',
      };
    case FETCH_PRODUCER_FORMS_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        currentProducerFormsPackage: null,
        error: action.payload,
      };
    case REFRESH_PRODUCER_FORMS_PACKAGES:
      const storedProducerFormsPackages = localStorage.getItem('producer_forms_packages');
      let formsPackagesResult = [];
      let formsPackagesInitial = false;
      if (storedProducerFormsPackages) {
        const producerFormsPackages = JSON.parse(storedProducerFormsPackages);
        if (producerFormsPackages.length > 0) {
          formsPackagesResult = producerFormsPackages;
          formsPackagesInitial = true;
        }
      }
      return {
        ...state,
        producerFormsPackages: formsPackagesResult,
        initialProducerFormsPackages: formsPackagesInitial,
      };
    case SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SEASON: {
      const producerFormsPackages = state.producerFormsPackages;
      let currentProducerFormsPackage = null;
	    currentProducerFormsPackage = producerFormsPackages.find(function (formsPackage) {
        // if (formsPackage.field_season.id === action.seasonID) {
        //   return formsPackage;
        // }
        return formsPackage.field_season.id === action.seasonID ? formsPackage : null;
	    });
      return {
        ...state,
        currentProducerFormsPackage: currentProducerFormsPackage,
      };
    };
    case SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_ID: {
      const producerFormsPackages = state.producerFormsPackages;
      let currentProducerFormsPackage = null;
      currentProducerFormsPackage = producerFormsPackages.find(function (formsPackage) {
        // if (formsPackage.id === action.rfpID) {
        //   return formsPackage;
        // }
        return formsPackage.id === action.rfpID ? formsPackage : null;
      });
      return {
        ...state,
        currentProducerFormsPackage: currentProducerFormsPackage,
      };
    };
    case SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_SALES_REPORT:
      const producerFormsPackages = state.producerFormsPackages;
      const currentProducerFormsPackage = producerFormsPackages.find(producerFormsPackage =>
        producerFormsPackage.field_domestic_sales_report.some(domestic_sales_report => domestic_sales_report.id === action.domesticSalesReportID)
      );
      return {
        ...state,
        currentProducerFormsPackage: currentProducerFormsPackage,
      };
    case SELECT_CURRENT_PRODUCER_FORMS_PACKAGE_BY_EXEMPTION:
      const producerFormsPackagesEx = state.producerFormsPackages;
      const currentProducerFormsPackageEx = producerFormsPackagesEx.find(producerFormsPackage =>
        producerFormsPackage.field_exemption.some(exemption => exemption.id === action.exemptionID)
      );
      return {
        ...state,
        currentProducerFormsPackage: currentProducerFormsPackageEx,
      };
    case CLEAR_CURRENT_PRODUCER_FORMS_PACKAGE:
      return {
        ...state,
        currentProducerFormsPackage: null,
      };
    case POST_PRODUCER_STATE_PRODUCT_ITEM_SUCCESS: {
      let saleRefs = state.saleReferences;
      saleRefs.push(action.payload);
      return {
        ...state,
        saleReferences: saleRefs,
      };
    }
    case POST_PRODUCER_STATE_PRODUCT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PRODUCER_STATE_PRODUCT_ITEMS:
      return {
        ...state,
        saleReferences: [],
      };
    case CREATE_DOMESTIC_SALES_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DOMESTIC_SALES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_DOMESTIC_SALES_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PATCH_DOMESTIC_SALES_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_DOMESTIC_SALES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case PATCH_DOMESTIC_SALES_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_EXEMPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_EXEMPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_EXEMPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PATCH_EXEMPTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PATCH_EXEMPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PATCH_EXEMPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default formsPackageReducer;
